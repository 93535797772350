export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: 'id',
    label: '#',
    thClass: 'w-50px',
    hideToggle: true,
    skipExport: true,
  },
  {
    key: 'createdAt',
    label: 'field.dateTime',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.userName',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'transferStatus',
    label: 'field.transferStatus',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'remark',
    label: 'field.remark',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
];
