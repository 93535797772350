export default [
  // {
  //   key: 'referenceNo',
  //   label: 'field.referenceNo',
  //   rules: 'max:200',
  //   type: 'text',
  // },
  // {
  //   key: 'arriveDate',
  //   label: 'field.arrivalDate',
  //   rules: 'required',
  //   type: 'date-picker',
  //   disableType: 'before',
  // },
  {
    key: 'file',
    cols: 12,
  }
]
