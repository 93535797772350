export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: 'id',
    label: '#',
    thClass: 'w-50px',
    hideToggle: true,
    skipExport: true,
  },
  {
    key: 'vehicleId',
    label: 'field.lot',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
    width: 2500,
  },
  {
    key: 'vinNumber',
    sortable: true,
    sortField: 'vinNumber',
    label: 'field.vinNumber',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
    width: 3000,
  },
  {
    key: 'year',
    label: 'field.year',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
    width: 2000,
  },
  {
    key: 'brandName',
    label: 'field.brand',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
    width: 2000,
  },
  {
    key: 'model',
    label: 'field.model',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
    width: 2000,
  },
  {
    key: 'sellTarget',
    label: 'field.channel',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
    width: 2000,
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-left',
    tdClass: 'text-left',
    hideToggle: true,
    width: 4500,
  },
  {
    key: 'transferStatus',
    label: 'field.transferStatus',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 4500,
  },
  {
    key: 'arrivalDate',
    label: 'field.arrivalDate',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3500,
  },
  {
    key: 'checkinDate',
    label: 'field.checkinDate',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3500,
  },
  {
    key: 'updatedBy',
    label: 'field.updatedBy',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 4000,
  },
  {
    key: 'engineCapacity',
    label: 'field.engineCapacity',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3000,
  },
  {
    key: 'transmission',
    label: 'field.transmission',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3500,
  },
  {
    key: 'variant',
    label: 'field.variant',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3000,
  },
  {
    key: 'colorName',
    label: 'field.color',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3000,
  },
  {
    key: 'drivetrain',
    label: 'field.drivetrain',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3000,
  },
  {
    key: 'auctionDate',
    label: 'field.auctionDate',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3500,
  },
  {
    key: 'reservePrice',
    label: 'field.reservePrice',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3000,
  },
  {
    key: 'buyNowPrice',
    label: 'field.buyNowPrice',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3000,
  },
  {
    key: 'finalPrice',
    label: 'field.finalPrice',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3000,
  },
  {
    key: 'revisedPrice',
    label: 'field.revisedPrice',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3000,
  },
  {
    key: 'inspectedAt',
    label: 'field.inspectedAt',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3500,
  },
  {
    key: 'inspectedBy',
    label: 'field.inspector',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 4000,
  },
  {
    key: 'location',
    label: 'field.location',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 4000,
  },
  {
    key: 'zone',
    label: 'field.zone',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 2000,
  },
  {
    key: 'checkoutDate',
    label: 'field.checkoutDate',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 3500,
  },
  // {
  //   key: 'dealer',
  //   label: 'field.dealer',
  //   thClass: 'text-left',
  //   tdClass: 'text-left',
  //   width: 4000,
  // },
  {
    key: 'dealerCompanyName',
    label: 'field.dealer',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 4000,
  },
  {
    key: 'bdPic',
    label: 'field.bdPic',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 4000,
  },
  {
    key: 'engineNumber',
    label: 'field.engineNumber',
    thClass: 'text-left',
    tdClass: 'text-left',
    width: 4000,
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    skipExport: true,
  },
];
