export default [
  {
    key: 'locationId',
    label: 'field.location',
    rules: 'required',
    type: 'asyn-single-selection',
    repository: 'location',
    selectionKey: 'id',
    selectionLabel: 'name',
    cols: 12,
  },
]
